<template>
  <Section class="discover-articles" wide-mode small-space>
    <h2 class="title">Articles similaires</h2>

    <article-swipe-container :items="articles">
      <template #item="{ item }">
        <article-card :key="item.id" :article="item" :parent-category-slug="parentCategorySlug" />
      </template>
    </article-swipe-container>
  </Section>
</template>

<script>
export default {
  props: {
    articles: {
      type: Array,
      required: true,
    },
    parentCategorySlug: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="less" scoped>
.discover-articles {
  overflow: hidden;
  background-color: var(--ds-color-primary-25);
  padding-top: 32px;
  padding-bottom: 32px;
}

.title {
  font-weight: 400;
  font-size: 24px;
  color: var(--ds-color-primary-100);
  margin-bottom: 24px;
}

:deep(.article-card) {
  margin-left: 32px;

  &:last-child {
    margin-right: 32px;
  }
}
</style>
